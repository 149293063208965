<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('suppliers') }}</div>
            <select v-model="selectedWebshop" @change="changeWebshop" class="px-5 py-1 rounded-sm focus:outline-none bg-white border text-xs text-gray-700">
                <option :value="webshop.uuid" v-for="webshop in GET_WEBSHOPS.results" :key="webshop.uuid">{{ webshop.shop_name }}</option>
            </select>
        </div>
        <div class="mt-4">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between items-center align-items-center w-full bg-light-teal p-1 border-b">
                        <div class="flex">
                            <span class="text-sm font-semibold">{{ $t('addSupplier') }}</span>

                        </div>
                        <button @click="addSupplierInfo" class="focus:outline-none bg-theme-blue text-sm px-3 py-1 rounded-md shadow-md text-white">
                            <span v-if="processing">{{ $t('adding') }}...</span>
                            <span v-else>{{ $t('addSupplier') }}</span>
                        </button>
                    </div>
                    <div class="flex flex-col md:flex-row w-full">
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpHost')}} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.host" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpUser')}} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.user" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpPassword')}} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.password" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpPort')}} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.port" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpBuyerID')}} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.buyerId" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('apiKey')}} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.apiKey" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('shippingPrice')}} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.shippingPrice" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('deliveryTime')}} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.deliveryTime" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('orderEmail')}} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.orderEmail" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2"></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <div class="flex space-x-2 mb-2">
                                        <input type="checkbox" class="checkbox" v-model="form.postProd">
                                        <span class="text-xs text-gray-600">{{ $t('postProduct')}}</span>
                                    </div>
                                    <div class="flex space-x-2 mb-4">
                                        <input type="checkbox" class="checkbox" v-model="form.syncOrder">
                                        <span class="text-xs text-gray-600">{{ $t('syncOrderOnly')}}</span>
                                    </div>
                                    <div class="flex mb-2">
                                        <!-- <span class="bg-theme-blue text-white px-4 py-1 rounded-md shadow-md focus:outline-none cursor-pointer">
                                            Connect
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="absolute bg-red-30 inset-0 z-10" @click="removeEvent" v-if="showActionBox"></div>
        <div class="mt-10 mb-20">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full bg-light-teal p-1 border-b">
                        <h2 class="text-sm font-semibold">{{ $t('supplierOverview')}}</h2>
                    </div>
                    <div class="flex w-full relative">
                        <div class="block w-full overflow-x-auto md:overflow-x-hidden overflow-y-hidden">
                            <table class="table table-fixed w-full relative mb-7" border="4">
                                <thead>
                                    <tr>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('supplier')}}</span></th>
                                        <!-- <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('date') }}</span></th> -->
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('status') }}</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Action</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="supplier in GET_SUPPLIERS.results" :key="supplier.id">
                                        <SupplierOverviewTableVue 
                                            :supplier="supplier"
                                            @toggleEvent="toggleEventAction"
                                            @emitDeleteEvent="emitDeleteEvent"
                                            @emitUpdateEvent="emitUpdateEvent"
                                            :systemSupplier="systemSuppliers"
                                        />
                                    </tr>
                                </tbody>
                            </table>
                            <span v-if="loading" class="flex justify-center w-full mb-10">
                                <svg class="text-center animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalBox v-if="openDeletedModal" :title="$t('supplier')" @handleClose='openDeletedModal = false' :backdropOff='false'>
            <div class="flex flex-col w-full overflow-x-hidden">
                <h1 class="text-gray-500">{{ $t('deleteSupplier') }}?</h1>
                <div class="flex justify-end mt-2">
                    <button @click="openDeletedModal = false" class="flex justify-center rounded whitespace-nowrap align-items-center  px-10 py-2 text-gray-100 text-gray-400 text-xs cursor-pointer border">Cancel</button>
                    <button @click="proceedDeletion" :disabled='deleting' type="submit" class="flex justify-center rounded whitespace-nowrap align-items-center bg-red-500 px-10 py-2 text-white text-xs cursor-pointer border">
                        <span class="flex" v-if="deleting">
                            <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            {{ $t('pleaseWait')}}.
                        </span>
                        <span v-else>{{ $t('yesDelete') }}</span>
                    </button>
                </div>
            </div>
        </ModalBox>
        <ModalBox v-if="openEditBox" :title="$t('updateSupplier')" width="md:w-3/4 w-full" @handleClose='openEditBox = false'>
            <div class="flex flex-col w-full overflow-x-hidden">
                <form @submit.prevent="updateSupplier" class="flex flex-col md:flex-row w-full">
                    <div class="flex flex-col w-full md:w-1/2">
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpHost')}} <span class="text-red-500">*</span></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <input type="text" v-model="selectedData.host" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpUser')}} <span class="text-red-500">*</span></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <input type="text" v-model="selectedData.sender_id" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpPassword')}} <span class="text-red-500">*</span></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <input type="text" v-model="selectedData.password" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpPort')}} <span class="text-red-500">*</span></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <input type="text" v-model="selectedData.incoming_port" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('ftpBuyerID')}} <span class="text-red-500">*</span></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <input type="text" v-model="selectedData.receiver_id" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/2">
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('apiKey')}} <span class="text-red-500">*</span></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <input type="text" v-model="selectedData.apiKey" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('shippingPrice')}} <span class="text-red-500">*</span></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <input type="text" v-model="selectedData.shipping_price" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('deliveryTime')}} <span class="text-red-500">*</span></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <input type="text" v-model="selectedData.deliveryTime" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('orderEmail')}} <span class="text-red-500">*</span></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <input type="text" v-model="selectedData.order_email" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                            <span class="text-sm text-gray-600 w-1/3 mb-2"></span>
                            <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                <div class="flex space-x-2 mb-2">
                                    <input type="checkbox" class="checkbox" v-model="selectedData.product_sync_only">
                                    <span class="text-xs text-gray-600">{{ $t('postProduct')}}</span>
                                </div>
                                <div class="flex space-x-2 mb-4">
                                    <input type="checkbox" class="checkbox" v-model="selectedData.order_sync_only">
                                    <span class="text-xs text-gray-600">{{ $t('syncOrderOnly')}}</span>
                                </div>
                                <div class="flex mb-2">
                                    <button :disabled="updating" class="focus:outline-none bg-theme-blue text-sm px-3 py-1 rounded-md shadow-md text-white">
                                        <span v-if="updating">{{ $t('updating') }}</span>
                                        <span v-else>{{ $t('update') }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </ModalBox>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Bus from '../../bus'
import ModalBox from '@/components/Modal'
import SupplierOverviewTableVue from './minicomponent/SupplierOverviewTable'
export default {
    name : 'Supplier',
    components : { SupplierOverviewTableVue, ModalBox },
    data () {
        return {
            selectedWebshop : null,
            systemSuppliers : [],
            processing : false,
            loading : true,
            updating : false,
            showActionBox : false,
            selectedData : {},
            openDeletedModal : false,
            openEditBox : false,
            deleting : false,
            form : {
                host : '',
                user : '',
                password : '',
                port : '',
                buyerId : '',
                apiKey : '',
                shippingPrice : '',
                deliveryTime : '',
                orderEmail : '',
                postProd : false,
                syncOrder : false,
            }
        }
    },
    watch : {
        // showActionBox : function (n, o) {
        //     if (!n) {
        //         Bus.emit('removeActionBox')
        //     }
        // }
    },
    computed : {
        ...mapGetters({
            USER_REFRESH : 'auth/USER_REFRESH',
            GET_WEBSHOPS : 'jitcontrol/GET_WEBSHOPS',
            GET_SUPPLIERS : 'jitcontrol/GET_SUPPLIERS',
            GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
            GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
        })
    },
    mounted () {
        this.getSystemSuppliers()
        this.getUser()
    },
    methods : {
        getUser () {
            if (this.GET_CHOOSEN_COMPANY) {
                this.getWebshop()
            }
        },
        // getUserInformation () {
        //     this.$store.dispatch('jitcontrol/getUserInfo')
        //     .then(_ => { 
        //         this.getWebshop()
        //     })
        //     .catch(_ => {
        //         // console.log()
        //         // if (err.response.status === 401) {
        //         //     this.$store.commit('auth/CLEAR_AUTH_USER', null)
        //         //     window.Bus.$emit('sign-out')
        //         // }
        //     })
        // },
        getSystemSuppliers () {
            const payload = {
                URL : this.$services.endpoints.SUPPLIER_ENDPOINT
            }
            this.$store.dispatch('jitcontrol/customGetRequest', payload)
            .then(res => {
                this.systemSuppliers = res.data.results
            })
            .catch(err => {
                this.loading = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.getSystemSuppliers()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        getWebshop () {
            if (this.GET_CHOOSEN_COMPANY) {
                const payload = {
                    company : this.GET_CHOOSEN_COMPANY
                }
                this.$store.dispatch('jitcontrol/getWebshop', payload)
                .then(_ => {
                    this.loading = false
                    if (this.GET_WEBSHOPS.results.length > 0) {
                        this.selectedWebshop = this.GET_WEBSHOPS.results[0].uuid
                        this.getSuppliers(this.GET_WEBSHOPS.results[0])
                    }
                })
                .catch(err => {
                    this.loading = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getWebshop()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            }
            // this.$store.dispatch('jitcontrol/getWebshop', {})
            // .then(_ => {
            //     if (this.GET_WEBSHOPS.results.length > 0) {
            //         this.selectedWebshop = this.GET_WEBSHOPS.results[0].uuid
            //         this.getSuppliers(this.GET_WEBSHOPS.results[0])
            //     }
            // })
            // .catch(_ => {
            // })
        },
        getSuppliers (data) {
            this.loading = true
            this.$store.dispatch('jitcontrol/getSuppliers', data)
            .then(res => {
                this.loading = false
            })
            .catch(err => {
                this.loading = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.getSuppliers(data)
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        changeWebshop (e) {
            this.loading = true
            this.getWebshop({ uuid : this.selectedWebshop })
        },
        addSupplierInfo () {
            this.processing = false
            const payload = {
                shipping_price: this.form.shippingPrice,
                average_delivery_time: this.form.deliveryTime,
                product_sync_only: this.form.postProd,
                order_sync_only: this.form.syncOrder,
                edi_user: true,
                sender_id: this.form.user,
                receiver_id: this.form.buyerId,
                max_product_sync: 0,
                order_email: this.form.orderEmail,
                URL : this.selectedWebshop + this.$services.endpoints.SUPPLIER_DETAILS
            }
            this.$store.dispatch('jitcontrol/customPostRequest', payload)
            .then(res => {
                this.processing = false
                Object.keys(this.form).forEach(item => { this.form[item] = '' })
                this.$services.helpers.notification(this.$t('success'), 'success', this)
            })
            .catch(err => {
                this.processing = false
                // if (err.response.status === 401) {
                //     window.Bus.$emit('sign-out')
                // }
                if (err.response.status === 400) {
                    this.$services.helpers.notification(err.response.data, 'error', this)
                }
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.addSupplierInfo()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        updateSupplier () {
            this.updating = true
            const payload = this.selectedData
            payload.URL = this.selectedWebshop + this.$services.endpoints.SUPPLIER_DETAILS
            this.$store.dispatch('jitcontrol/customPatchRequest', payload)
            .then(res => {
                this.updating = false
                this.getSuppliers({ uuid : this.selectedWebshop })
                this.$services.helpers.notification(this.$t('success'), 'success', this)
                this.openEditBox = false
                this.selectedData = {}
            })
            .catch(err => {
                this.updating = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.updateSupplier()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                } else {
                    this.$services.helpers.notification(err.response.data.message, 'error', this)
                }
            })
        },
        proceedDeletion () {
            this.deleting = true
            const payload = {
                URL : this.selectedWebshop + this.$services.endpoints.SUPPLIER_DETAILS + `/${this.selectedData.uuid}`
            }
            this.$store.dispatch('jitcontrol/customDeleteRequest', payload)
            .then(res => {
                this.deleting = false
                this.getSuppliers({ uuid : this.selectedWebshop })
                this.$services.helpers.notification(this.$t('success'), 'success', this)
                this.openDeletedModal = false
                this.selectedData = {}
            })
            .catch(err => {
                this.deleting = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.proceedDeletion()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                } else {
                    this.$services.helpers.notification(err.response.data.message, 'error', this)
                }
            })
        },
        toggleEventAction (e) {
            this.showActionBox = true
        },
        emitDeleteEvent (e) {
            this.selectedData = e
            this.showActionBox = false
            this.openDeletedModal = true
        },
        emitUpdateEvent (e) {
            this.selectedData = e
            this.showActionBox = false
            this.openEditBox = true
        },
        removeEvent () {
            Bus.emit('removeActionBox')
            this.showActionBox = false
        }
    }
}
</script>

<style lang="scss" scoped>
.table th {
    padding: .3rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table tbody, td {
    padding: .7rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}
</style>